<template>
	<div class="Y-2018">
		
		<MultiSplitQuadrants
			bottom-image="2018-china.jpg"
			top-image="2018-MIFID-2.jpg">

			<template v-slot:top>
        <YearPartial ref="year" :year="2018" />
        <HeadlinePartial ref="headline" :headline="$t('2018.bottom.headline')" />
				<BodyPartial ref="body" :body="$t('2018.bottom.body')" />
			</template>

      <template v-slot:bottom>
        <HeadlinePartial  :play-animation="true" :headline="$t('2018.top.headline')" />
        <BodyPartial :play-animation="true" :body="$t('2018.top.body')" />
				<Vimeo :vimeo-code="$t('2018.bottom.video')" />
      </template>
			
		</MultiSplitQuadrants>
		
	</div>
</template>

<script>
	import MultiSplitQuadrants from "@/components/layouts/MultiSplitQuadrants";
	import YearPartial from "@/components/partials/YearPartial";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import {bgcolor} from "@/mixins/bgcolor";
	import Vimeo from "@/components/partials/Vimeo";
	export default {
		name: "Y-2018",
		mixins: [bgcolor],
		data() {
			return {
				bgColor: 'light'
			}
		},
		components: {Vimeo, BodyPartial, HeadlinePartial, YearPartial, MultiSplitQuadrants}
	}
</script>

<style lang="scss">
  .Y-2018 {
    .top-right {
      img {
        object-position: bottom center;
      }
    }
		.bottom-right {
			@media screen and (max-width: $tablet) {
				display: flex;
				flex-direction: column;
				.vimeo {
					order: 2;
					margin-bottom: $mobile-pad;
				}
				h3 {
					order: 1;
				}
				p {
					order: 3;
				}
			}
		}
  }
</style>