<template>
	<div class="Y-1995">

		<Basic>

			<YearPartial ref="year" :year="1995" :pad="true" text-align="center" />

			<TwoColumn :pad-col-right="true">
				<template v-slot:left>
					<ImagePartial ref="image" :full-height="true" image="1995-richard.jpg" />
				</template>

				<template v-slot:right>
					<HeadlinePartial ref="headline" :headline="$t('1995.top.headline')" />
					<BodyPartial :play-animation="true" :body="$t('1995.top.body-1')" />
					<BodyPartial :play-animation="true" :body="$t('1995.top.body-2')" />
					<AudioPartial v-if="$root.$i18n.locale === 'en'" :heading="$t('1995.top.caption')" file-name="richard-joins-burgundy.mp3" />
					<Link v-if="$root.$i18n.locale === 'fr'" :link-text="$t('1995.top.caption')" :url="$t('1995.top.url')" :play-animation="true" />
				</template>
			</TwoColumn>

			<SingleColumn class-prop="top-quote" text-align="left">
				<PullQuotePartial ref="quote" :author="$t('1995.top.author')" :quote="$t('1995.top.inline-quote')" />
			</SingleColumn>

			<TwoColumn class-prop="bottom reduced-padding-bottom" :pad-col-left="true" :mobile-reorder="true">
				<template v-slot:left>
					<HeadlinePartial  :play-animation="true" :headline="$t('1995.bottom.headline')" />
					<BodyPartial :play-animation="true" :body="$t('1995.bottom.body')" />
				</template>
				<template v-slot:right>
					<ImagePartial image="1995-allan.jpg" />
				</template>
			</TwoColumn>

			<TwoColumn class-prop="bottom reduced-padding-top" :pad-col-right="true" :v-align-right="`center`">
				<template v-slot:left>
					<ImagePartial image="1995-andrew.jpg" />
				</template>
				<template v-slot:right>
					<PullQuotePartial :quote="$t('1995.bottom.pull-quote')" :author="$t('1995.bottom.author')" />
				</template>
			</TwoColumn>

		</Basic>

	</div>
</template>

<script>

import HeadlinePartial from "@/components/partials/HeadlinePartial";
import BodyPartial from "@/components/partials/BodyPartial";
import YearPartial from "@/components/partials/YearPartial";
import TwoColumn from "@/components/sub-layouts/TwoColumn";
import ImagePartial from "@/components/partials/ImagePartial";
import PullQuotePartial from "@/components/partials/PullQuotePartial"
import AudioPartial from "@/components/partials/AudioPartial";
import {bgcolor} from "@/mixins/bgcolor";
import Link from "@/components/partials/Link";
import SingleColumn from "../sub-layouts/SingleColumn";
import Basic from "../layouts/Basic";

export default {
	name: "Y-1995",
	mixins: [bgcolor],
	data() {
		return {
			bgColor: 'light'
		}
	},
	components: {
		Basic,
		SingleColumn,
		Link,
		AudioPartial,
		PullQuotePartial,
		ImagePartial,
		TwoColumn,
		YearPartial,
		BodyPartial,
		HeadlinePartial,
	},
}
</script>

<style lang="scss">

	.fr {
		.Y-1995 {

		}
	}

	.Y-1995 {
		.basic {
			padding-bottom: 0;
		}
		.top-quote {
			margin-bottom: $desktop-pad;
			margin-top: calc(#{$desktop-pad} * 1.5);
		}
		.bottom {
			background-color: $cream;
			color: $deep-blue;
			padding-top: $desktop-pad;
			padding-bottom: $desktop-pad;
		}
    .reduced-padding-top {
      padding-top: 0;
    }
    .reduced-padding-bottom {
      padding-bottom: 0;
    }
		.image-container.full-height {
			min-height: auto;
			img {
				min-height: auto;
			}
		}

		@media screen and (max-width: $tablet) {
			.image-container {
				margin-bottom: $mobile-pad;
			}
			.top-quote {
				margin-bottom: $mobile-pad;
				margin-top: 0;
			}
			.bottom {
				padding-top: $mobile-pad;
				padding-bottom: $mobile-pad;
				.pull-quote-container {
					margin-top: $mobile-pad;
				}
			}
		}
	}

</style>
